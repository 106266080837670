export default{
    title: 'dark',

    colors:{
        primary: '#712831',
        secundary: '#94A3B8',

        background: '#232532',
        text: '#FFFFFF',
        buttonText: '#fff',

        shadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',

        backgroundpresentation: 'radial-gradient(46.97% 53.54% at 50% 46.46%, rgba(0, 250, 220, 0.2) 0%, rgba(0, 1, 2, 0) 100%), #232532',
        backgroundpresentation780: 'radial-gradient(97% 53.54% at 50% 46.46%, rgba(0, 250, 220, 0.2) 0%, rgba(0, 1, 2, 0) 100%), linear-gradient(0deg, #232532, #232532), rgb(255, 255, 255)',
    }
}